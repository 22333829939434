@import '../../../site/styles/settings/mixins.scss';

@include remove-grid-content('.cmp-details-metadata, .cmp-details-renditions, .cmp-details-action-buttons, .cmp-details-title, .cmp-details-tags');

.brandportal.assetsdetailspage {
  .cmp-asset-details__title {
    display: flex;
    flex-flow: column;
    gap: toRem(16px);

    @media (--breakpoint-up-md) {
      flex-flow: row;
      gap: toRem(32px);
      align-items: center;
      padding: 0 0 toRem(32px) 0;
    }

    &-link {
      display: flex;
      gap: var(--spacing-xs);
    }

    &-content {
      font-weight: var(--font-weight);
      font-size: var(--font-size-h3);
      line-height: toRem(36px);
    }
  }

  .cmp-details-image,
  .cmp-details-video {
    background-color: var(--color-gray-50);
    padding: 0;
    display: flex;
    justify-content: center;

    @media (--breakpoint-up-md) {
      margin: 0 0 var(--spacing-xxl) toRem(-12px);
    }

    video {
      max-height: toRem(600px);
    }
  }

  .cmp-details-metadata,
  .cmp-details-tags {
    padding: 0 1rem;

    @media (--breakpoint-up-md) {
      margin-left: var(--spacing-lg);
    }

    &__wrapper {
      border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);
      padding: var(--spacing-md) 0;
    }

    &__wrapper .label {
      padding: var(--spacing-xs) var(--spacing-sm);
    }

    &__title {
      font-weight: var(--font-weight);
      font-size: toRem(12px);
      line-height: toRem(16px);
      color: var(--color-gray-900);
      margin-bottom: toRem(4px);
      display: block;
    }

    p {
      @include bold-lg();
    }

    &__tags {
      display: flex;
      flex-flow: wrap;
      gap: var(--spacing-xs);

      &-label {
        padding: var(--spacing-xxs) var(--spacing-sm);
        border: toRem(1px) solid var(--color-gray-100);
        font-size: toRem(12px);
        line-height: toRem(16px);
      }
    }
  }

  .cmp-action-buttons {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--spacing-md);

    &__title {
      @include bold-lg();

      width: 100%;
    }

    &__button {
      border: var(--spacing-xxxs) solid var(--color-primary-500);
      padding: var(--spacing-xs) var(--spacing-lg);
      margin: 0;
      font-weight: var(--font-weight-bold);
      /* stylelint-disable-next-line declaration-no-important */
      margin-bottom: var(--spacing-sm) !important;

      @media (--breakpoint-down-md) {
        padding: var(--spacing-xs) var(--spacing-sm);
      }

      &:hover {
        border-color: var(--color-primary-700);
        box-shadow: 0 0 0 var(--spacing-xxxs) var(--color-primary-700);
        color: var(--color-primary-700);
      }

      &--primary {
        color: var(--color-white);
        background-color: var(--color-gray-500);
        border: none;

        &:hover {
          box-shadow: none;
          background-color: var(--color-primary-700);
          color: var(--color-white);
        }
      }

      .cmp-button__icon {
        margin-right: var(--spacing-xxs);
        font-weight: var(--font-weight);
      }
    }

    & .hidden {
      display: none;
    }
  }

  @media (--breakpoint-up-md) {
    .cmp-details-renditions {
      padding-bottom: toRem(50px);
    }

    .cmp-layoutcontainer__column:first-child {
      border-right: 1px solid var(--color-gray-100);
      margin-bottom: toRem(-50px);
    }
  }

  @media (--breakpoint-down-md) {
    .cmp-layoutcontainer__column:last-child {
      margin-bottom: var(--spacing-xxl);
    }
  }
}
