/* stylelint-disable  selector-no-qualifying-type */
/* stylelint-disable declaration-no-important */
$primary: var(--color-primary-500) !important;

.brandportal {
  a:hover {
    color: var(--color-primary-700);
  }

  & .cmp-breadcrumb__item .cmp-breadcrumb__item-link:hover {
    color: var(--color-gray-500);
  }
  /* stylelint-disable-next-line selector-max-compound-selectors */
  .ui.positive.message,
  .ui.toggle.checkbox input:checked ~ .box::before,
  .ui.toggle.checkbox input:checked ~ label::before,
  .ui.calendar .ui.table tr td.range {
    background-color: var(--color-primary-500) !important;
  }

  .ui.checkbox input:checked ~ .box::before,
  .ui.checkbox input:checked ~ label::before,
  .ui.checkbox input:focus ~ .box::before,
  .ui.checkbox input:focus ~ label::before,
  .ui.checkbox input:checked:focus ~ .box::before,
  .ui.checkbox input:checked:focus ~ label::before,
  .ui.checkbox input:not([type='radio']):indeterminate:focus ~ .box::before,
  .ui.checkbox input:not([type='radio']):indeterminate:focus ~ label::before,
  .ui.checkbox .box:active::before,
  .ui.checkbox label:active::before {
    border-color: var(--color-primary-500);
  }

  .ui.button.link:hover {
    color: var(--color-primary-500);
  }

  .ui.checkbox input:checked ~ .box::after,
  .ui.checkbox input:checked ~ label::after {
    color: var(--color-gray-700) !important;
  }

  .ui.card,
  .ui.cards > .card {
    border-radius: 0;

    &:hover {
      box-shadow: var(--box-shadow-lg);
    }
  }

  a.image.cmp-image__wrapper--card {
    border-radius: 0 !important;
  }

  .cmp-asset-share-modal.ui.modal .actions,
  .cmp-asset-sharecart-modal.ui.modal .actions,
  .cmp-asset-download-modal.ui.modal .actions {
    .cmp-button__icon {
      font-weight: initial;
    }

    .cmp-button[data-asset-share-id='download-all'],
    .cmp-button[data-asset-share-id='submit-share'],
    .cmp-button.download-btn {
      @include button-primary;
    }

    .cmp-button[data-asset-share-id='share-all'],
    .cmp-button.cmp-button--deny.deny {
      @include button-secondary;
    }

    .cmp-button[data-asset-share-id='download-all'],
    .cmp-button[data-asset-share-id='submit-share'],
    .cmp-button.download-btn,
    .cmp-button[data-asset-share-id='share-all'],
    .cmp-button.cmp-button--deny.deny {
      display: flex;
    }
  }

  .cmp-text h5 {
    color: var(--color-gray-900);
  }

  .cmp-linklist__items--card .cmp-linklist__item-link:hover,
  .button.cmp-button--primary-positive a:hover,
  .cmp-cta__button.button.cmp-button--primary-positive a:hover {
    color: var(--color-white);
  }

  .cmp-list__item .cmp-list__item-article .cmp-list__item-link:hover {
    color: var(--color-gray-900);
  }

  .cmp-newslist__content-wrapper .cmp-newslist__link:hover {
    color: var(--color-gray-900);
  }

  .cmp-text a:hover {
    color: var(--color-gray-900);
  }

  .cmp-contact__link:hover {
    color: var(--color-gray-900);
  }

  .cmp-footercmp .cmp-footercmp__button:hover {
    color: var(--color-white);
  }

  .cmp-footer .cmp-button:hover,
  .cmp-footer .cmp-list__item,
  .cmp-footer .cmp-list__item-link,
  .cmp-footer .cmp-list__item-link:hover,
  .cmp-footer .cmp-list__item:hover {
    color: var(--color-white) !important;
  }

  .ui.checkbox.checkbox label::before {
    border: 0.125rem solid var(--color-gray-700) !important;
  }

  .cmp-search-filter-toggle .ui.button {
    line-height: var(--line-height) !important;
  }

  .ui.checkbox.checkbox label::after {
    font-family: 'custom_materialicons', sans-serif;
    content: var(--icon-check) !important;

    @include bold-md();
  }

  .cmp-header__logout .cmp-header__top-item-title-text {
    font-weight: 400;
    color: var(--color-gray-900);
  }

  .cmp-header__logout .cmp-header__top-item-profile .cmp-header__top-item-title-text:hover,
  .cmp-header__logout .cmp-header__top-item-logout .cmp-header__top-item-title-text:hover {
    text-decoration: underline;
  }

  .cmp-header__top-item-profile {
    cursor: pointer;
  }

  .cmp-header__dropdown.cmp-header__logout {
    position: relative;
  }

  .cmp-header__overlay.cmp-header__overlay--visible {
    background: unset;
  }

  .cmp-header__dropdown--open.cmp-header__top-item-content--small {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }

  .cmp-header__top-item-logout {
    margin-left: 0;
    padding-bottom: var(--spacing-sm);
    padding-top: var(--spacing-sm);
  }

  .ui.dropdown .menu {
    height: auto;
  }

  .cmp-header__top-item:not([aria-expanded='true']) {
    &:hover {
      *:not(.label) {
        color: var(--color-primary-700);
      }
    }

    &:active {
      *:not(.label) {
        color: var(--color-primary-800);
      }
    }
  }

  .cmp-navigation__item--level-0 a:hover {
    color: var(--color-primary-500);
  }

  .cmp-navigation .cmp-navigation__column .cmp-navigation__item.cmp-navigation__item--level-1 {
    @include bold-xl();
  }

  .cmp-navigation__item--level-1 a:hover {
    color: var(--color-black);
  }

  .cmp-navigation__item--level-2:hover {
    color: var(--color-primary-500) !important;
  }

  .cmp-navigation__group--l3 a:hover {
    color: var(--color-primary-500);
    text-decoration: underline;
    text-decoration-color: var(--color-primary-500);
    text-underline-offset: toRem(4px);
  }

  .the-wall-teaser-container {
    width: auto !important;
    float: none !important;
    clear: both !important;
  }

  .the-wall-wrapper {
    min-height: 100%;
  }

  .the-wall {
    padding-bottom: 0;
  }
}

.termsandconditionspage .cmp-title {
  padding-bottom: toRem(20px);
}
