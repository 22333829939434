.brandportal {
  .cmp-structure-messages .cmp-messages {
    position: fixed;
    bottom: unset;
    left: unset;
    right: unset;
    width: 100%;
  }

  .cmp-structure-messages .cmp-message--wrapper {
    border-width: toRem(1px) toRem(1px) toRem(1px) toRem(5px);
    border-style: solid;
    border-color: var(--color-success-500);
    background: white;
    padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) var(--spacing-lg);
  }

  .cmp-structure-messages .cmp-message__text {
    @include bold-xl();
  }

  .cmp-structure-messages .cmp-message--wrapper__icon {
    color: var(--color-success-500);
    font-weight: var(--font-weight);
    padding-right: var(--spacing-xs);
  }
}
