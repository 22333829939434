/* stylelint-disable selector-max-compound-selectors */

.brandportal {
  .cmp-asset-share-modal,
  .cmp-asset-sharecart-modal,
  .cmp-asset-download-modal {
    &.ui.modal {
      > .header {
        font-weight: var(--font-weight);
        font-size: var(--font-size-h3);
        line-height: toRem(36px);
        border-bottom: none;
        padding: toRem(32px) toRem(48px);
      }

      .cmp-modal-cart__table,
      .cmp-modal-sharecart__table,
      .cmp-modal-download__table {
        &-row {
          border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);

          &:last-child {
            border-bottom: none;
          }

          &-cell {
            &:first-child {
              width: toRem(120px);
            }

            padding: var(--spacing-sm);
            padding-left: 0;
            vertical-align: middle;

            img {
              width: toRem(100px);
              height: toRem(100px);
              object-fit: cover;
            }
          }

          &-button:hover {
            color: var(--color-primary-700);
          }
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        gap: var(--spacing-lg);
        background: white;
        border-top: none;

        @media #{$mq-1} {
          flex-wrap: wrap;
          gap: var(--spacing-sm);
        }

        .cmp-button {
          margin: 0;
          height: toRem(40px);
          padding: var(--spacing-xs) var(--spacing-md);
          cursor: pointer;
          display: flex;
          gap: var(--spacing-xs);
          text-align: center;

          @media #{$mq-1} {
            padding: 0;
          }

          &--clear {
            margin-right: auto;
            background: transparent;
            color: var(--color-primary-500);
            text-decoration: underline;

            @media #{$mq-1} {
              flex: 100%;
              justify-content: flex-end;
            }

            &:hover {
              background-color: transparent;
              color: var(--color-primary-700);
            }
          }

          &.disabled {
            background-color: var(--color-gray-100);
            color: var(--color-gray-500);
            pointer-events: none;
          }
        }

        @media (--breakpoint-down-md) {
          /* stylelint-disable-next-line declaration-no-important */
          padding: 1rem !important;
        }
      }

      > .close {
        background-color: var(--color-gray-700);
        width: toRem(48px);
        height: toRem(48px);
        margin-left: auto;
        display: flex;
        justify-content: center;
        /* stylelint-disable-next-line declaration-no-important */
        top: 0 !important;
        /* stylelint-disable-next-line declaration-no-important */
        right: 0 !important;
        align-items: center;
        padding: 0;

        .cmp-button__icon {
          font-size: toRem(30px);
          color: var(--color-white);
        }
      }

      > .content {
        padding: var(--spacing-xxs) var(--spacing-xxxl);
      }

      .cmp-modal__content {
        /* stylelint-disable-next-line declaration-no-important */
        display: flex !important;
        gap: var(--spacing-lg);

        @media (--breakpoint-down-md) {
          flex-flow: column;
        }

        &-list {
          flex: 50%;

          &-title {
            @include bold-xl();

            color: var(--color-gray-900);
            margin-bottom: toRem(20px);
            display: block;
          }

          &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);
            padding: var(--spacing-xs) 0;

            &__title {
              @include bold-md();
            }
          }

          &-field {
            &:not(last-child) {
              margin-bottom: var(--spacing-md);
            }

            input,
            textarea {
              border: var(--spacing-xxxs) solid var(--color-gray-700);
              border-radius: 0;
            }

            .ui.checkbox label:hover::before {
              border: var(--spacing-xxxs) solid var(--color-gray-700);
            }
          }
        }
      }
    }
  }

  .cmp-modal-download__asset-rendition-group {
    padding-bottom: var(--spacing-md);
  }

  .ui.modal > .dimmer:first-child + .icon + *,
  .ui.modal > .dimmer:first-child + :not(.icon),
  .ui.modal > .icon:first-child + *,
  .ui.modal > :first-child:not(.icon, .dimmer) {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }
}
