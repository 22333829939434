.brandportal {
  .page-stage {
    padding-top: 0;
  }

  .page-stagev2 {
    @media (--breakpoint-down-md) {
      .cmp-banner-cta__content--side-aligned .cmp-banner-cta__content-inner {
        width: 100%;
      }
    }

    @media (--breakpoint-header-down-md) {
      .cmp-banner-cta__pattern--top-left::before,
      .cmp-banner-cta__pattern--top-right::before,
      .cmp-banner-cta__pattern--bottom-right::before,
      .cmp-banner-cta__pattern--top-right-triple::before {
        background-image: url('/src/main/webpack/resources/brandPatternSingle.svg');
      }
    }

    @media (--breakpoint-down-sm) {
      .cmp-banner-cta__content--side-aligned .cmp-banner-cta__content-inner {
        width: unset;
      }
    }
  }
}
