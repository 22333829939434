/* stylelint-disable selector-max-compound-selectors */

@include remove-grid-content('.searchresultsheader');

.brandportal .searchresultsheader {
  display: flex;
  justify-content: space-between;

  @media (--breakpoint-down-sm) {
    flex-flow: column;
    gap: 1.25rem;
    flex-direction: column-reverse;
  }

  .cmp-total-results {
    font-weight: var(--font-weight-bold);
    display: flex;
  }

  .cmp-search-sorting {
    display: flex;
    align-items: center;
    gap: 1.25rem;

    @media (--breakpoint-down-sm) {
      justify-content: flex-end;
    }

    &__button {
      color: var(--color-gray-300);
      margin: 0;

      .cmp-button__icon {
        font-size: var(--font-size-icon-sm);
      }

      &--active {
        color: var(--black);

        .cmp-button__icon {
          font-size: var(--font-size-icon-md);
        }
      }

      &:hover {
        color: var(--color-primary-700);
      }
    }

    .ui {
      &.icon {
        &.cmp-search-sorting__button {
          padding: 0;
        }
      }

      &.dropdown {
        display: flex;
        gap: var(--spacing-xxs);

        .cmp-button__icon {
          &::before {
            content: '\f141';
            font-weight: var(--font-weight-bold);
          }

          &:hover {
            color: var(--color-primary-700);
          }
        }

        .menu {
          .item:hover {
            background-color: var(--color-primary-700);
            color: var(--color-white);
          }
        }
      }

      &.active {
        .cmp-button__icon {
          &::before {
            content: '\f13e';
          }
        }
      }
    }
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  cq {
    display: none;
  }
}
