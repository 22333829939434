.brandportal {
  .cart-button {
    display: flex;
    cursor: pointer;

    .cmp-button {
      /* stylelint-disable-next-line declaration-no-important */
      display: flex !important;
      /* stylelint-disable-next-line declaration-no-important */
      width: auto !important;

      .cmp-button__icon {
        color: var(--color-black);
      }

      .label {
        background-color: var(--color-primary-500);
        color: var(--color-white);
        border-radius: 50%;
        width: var(--icon-width);
        height: var(--icon-height);
        display: flex;
        justify-content: center;
        margin-left: var(--spacing-xxs);
        place-items: center;
      }
    }

    @media (--breakpoint-header-down-md) {
      .cmp-button {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  .cmp-header--sticky .cmp-button .cmp-button__icon {
    color: var(--color-white);
  }
}
