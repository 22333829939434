.brandportal.loginpage {
  .page-stagev2 {
    .cmp-text.page-stagev2-inner__text {
      p {
        @include regular-md();
      }
    }

    .cmp-button {
      .cmp-button__text {
        line-height: 130%;
      }
    }

    .cmp-banner-cta__button {
      display: flex;
      gap: var(--spacing-lg);
    }
  }

  .page-stagev2__wrapper .cmp-banner-cta__content-inner {
    margin: 0 0 var(--spacing-xxxl) var(--spacing-5xl);
  }

  @media (--breakpoint-down-md) {
    .page-stagev2__wrapper .cmp-banner-cta__content-inner {
      margin: 0 0 var(--spacing-lg) var(--spacing-lg);
    }
  }

  @media (--breakpoint-down-sm) {
    .page-stagev2__wrapper .cmp-banner-cta__content-inner {
      margin: 0 var(--spacing-lg) 0 var(--spacing-lg);
    }
  }

  .text {
    .cmp-text {
      padding: var(--spacing-xxxl) 0 var(--spacing-4xl) 0;

      p {
        margin-bottom: var(--spacing-md);
      }
    }

    @media (--breakpoint-down-md) {
      .cmp-text {
        padding: var(--spacing-xxxl) var(--spacing-xxl) var(--spacing-4xl) var(--spacing-xxl);
      }
    }
  }

  @media (--breakpoint-down-lg) {
    main .aem-Grid {
      margin-left: 0;
      margin-right: 0;
    }

    main {
      margin: 0;
    }
  }

  .aem-GridColumn {
    padding: 0;
  }

  .aem-GridColumn:not(
  .search,
  .teasercontainer,
  .hidden,
  .smallstage,
  .mediumstage,
  .textonlystage,
  .pagestage,
  .separator,
  .title,
  .breadcrumb,
  .button,
  .contact,
  .container,
  .videocontainer,
  .teaser,
  .embed,
  .findjobs,
  .entitylist,
  .overflowed,
  .bignumbers,
  .cmp-stage-hero-slider--nospacing,
  .carousel.eventList,
  .cmp-search-filter-toggle,
  .cmp-search-property,
  .searchresultsheader,
  .cmp-details-metadata,
  .cmp-details-renditions,
  .cmp-details-action-buttons,
  .cmp-details-title,
  .cmp-details-tags,
  .cmp-search-date-range,
  .cmp-search-tags,
  .daterange,
  .cmp-search-results,
  .cmp-search-path,
  .saas-search-results__wrapper) > *::after {
    padding-top: 0;
  }

  .footer.experiencefragment.cmp-footer {
    padding-top: var(--spacing-lg);
  }

  @media (--breakpoint-down-md) {
    .page-stagev2 {
      width: 100%;
      margin: 0;
    }
  }
}
