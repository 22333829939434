/* stylelint-disable selector-max-compound-selectors */

.brandportal .cmp-search-search-bar {
  padding-left: var(--spacing-6xl);
  padding-right: var(--spacing-6xl);

  .ui {
    &.input {
      height: toRem(40px);

      .cmp-search-search-bar--input {
        border-radius: 0;
        border-color: var(--color-gray-700);
        border-right: none;
        font-size: toRem(14px);

        &::placeholder {
          color: var(--color-gray-900);
        }
      }

      .cmp-search-search-bar--button {
        padding-right: var(--spacing-md);
        background-color: var(--color-white);
        border: var(--spacing-xxxs) solid var(--color-gray-700);
        border-left: none;

        .cmp-button__icon {
          color: var(--color-black);
        }
      }
    }
  }

  + .cmp-layoutcontainer__column {
    padding: 0 var(--spacing-lg);
  }

  @media (--breakpoint-down-md) {
    padding-left: 0;
    padding-right: 0;
  }
}
