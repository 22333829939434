@include remove-grid-content('.cmp-search-filter-toggle');

.brandportal .cmp-search-filter-toggle {
  margin-left: var(--spacing-xxl);
  display: flex;
  border-bottom: var(--spacing-xxxs) solid var(--color-gray-300);
  border-left: var(--spacing-xxxs) solid var(--color-gray-300);
  /* stylelint-disable-next-line declaration-no-important */
  padding: toRem(24px) toRem(33px) !important;

  @media (--breakpoint-down-md) {
    margin: 0 0 toRem(24px) 0;
    border-right: var(--spacing-xxxs) solid var(--color-gray-300);
  }

  .cmp-button {
    display: flex;
    width: 100%;

    .button {
      border-radius: 0;
      background-color: var(--color-primary-500);
      color: var(--color-white);
      padding: toRem(8px) toRem(20px);

      &:hover {
        background-color: var(--color-primary-700);
        color: var(--color-white);
      }

      &.basic {
        background: transparent;
        color: var(--color-primary-500);
        text-decoration: underline;
        font-weight: var(--font);

        &:hover {
          color: var(--color-primary-700);
        }
      }
    }
  }
}
