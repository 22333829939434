@include remove-grid-content('.daterange, .cmp-search-date-range, .cmp-search-path, .cmp-search-property, .cmp-search-tags');

.brandportal {
  /* stylelint-disable selector-max-compound-selectors */
  .cmp-search-property,
  .cmp-search-date-range,
  .daterange,
  .cmp-search-tags,
  .cmp-search-path {
    border-bottom: var(--spacing-xxxs) solid var(--color-gray-300);
    border-left: var(--spacing-xxxs) solid var(--color-gray-300);
    border-right: var(--spacing-xxxs) solid var(--color-gray-300);
    padding: var(--spacing-xxs);

    &:first-child {
      border-top: var(--spacing-xxxs) solid var(--color-gray-300);
    }

    @media (--breakpoint-up-md) {
      margin-left: var(--spacing-xxl);
    }

    @media (--breakpoint-down-md) {
      border-right: var(--spacing-xxxs) solid var(--color-gray-300);
    }

    .styled {
      &.ui {
        &.accordion {
          .title {
            display: flex;
            justify-content: space-between;
            border: none;

            .cmp-button__icon {
              color: var(--color-black);

              &::before {
                content: '\f141';
              }
            }

            &.active {
              .cmp-button__icon {
                &::before {
                  content: '\f13e';
                }
              }
            }
          }

          .filter-category {
            color: var(--color-primary-500);
            font-weight: var(--font-weight-bold);
          }

          .content {
            label {
              color: var(--color-gray-900);

              &::before {
                border: var(--spacing-xxxs) solid rgb(55 55 55 / 50%);
              }

              &::after {
                color: var(--color-primary-500);
              }
            }

            .ui.radio.checkbox {
              label {
                &::after {
                  background-color: var(--color-primary-500);
                }
              }
            }

            .ui.toggle.checkbox input:checked ~ label::before,
            .ui.slider.checkbox input:checked ~ label::before {
              /* stylelint-disable-next-line declaration-no-important */
              background-color: var(--color-primary-500) !important;
            }

            .ui.dropdown {
              padding: var(--spacing-xs) var(--spacing-sm);
              border: var(--spacing-xxxs) solid rgb(55 55 55 / 50%);
              border-radius: 0.2857rem;
              background-color: var(--color-white);

              .default.text {
                color: var(--color-gray-900);
              }

              .menu {
                .item:hover {
                  background-color: var(--color-primary-700);
                  color: var(--color-white);
                }
              }

              .icon {
                &::before {
                  content: '\f141';
                  color: var(--color-gray-900);
                  font-size: var(--sm-font-size-h4);
                }
              }

              &.active {
                .icon {
                  &::before {
                    content: '\f13e';
                  }
                }
              }
            }
          }

          .child-tag-filters-wrapper {
            padding: 0;
            margin-left: 30px;
          }
        }
      }
    }
  }

  .daterange,
  .cmp-search-date-range {
    .rangestart .ui.input,
    .rangeend .ui.input {
      display: flex;
      align-items: center;
      gap: 12px;

      .cmp-button__icon {
        color: var(--color-primary-500);
        position: absolute;
        left: 8px;
      }
    }
  }

  .cmp-search-filter-toggle {
    /* stylelint-disable-next-line declaration-no-important */
    border-right: var(--spacing-xxxs) solid var(--color-gray-300) !important;
  }
}
